@import "./variables";
.privacy {
  padding: 30px 0 100px;
  .privacy-cont {
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 15px 15px;
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 35px;
      /* or 159% */
      color: $purple;
    }
    .first-para {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #707070;
      margin-bottom: 40px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color: #707070;
    }
    h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #2f2f2f;
    }
    h5 {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $green;
    }
    ul {
      li {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        color: #707070;
      }
    }
  }
}
