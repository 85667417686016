@import "./variables";
:where(
    .css-dev-only-do-not-override-acm2ia
  )[ant-click-animating-without-extra-node="true"]::after,
:where(.css-dev-only-do-not-override-acm2ia) .ant-click-animating-node {
  opacity: initial;
  inset-inline-start: initial;
  inset-inline-end: initial;
  animation: initial;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.buy-token {
  .buy-token-btn {
    background: #f5f5f5;
    padding: 27px 0;
    .wallet-btn {
      @include greenButton;
      margin: 0 auto;
      &:after {
        content: url("../../assets/images/triangle.png");
        position: absolute;
        top: -1px;
        right: -1px;
        animation: initial;
      }
      img {
        margin-left: 8px;
      }
    }
  }

  .token-price {
    padding: 40px 0;
    position: relative;

    .token-price-cont {
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 35px;
        background: #4a57e4;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 5px;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: $darkGrey;
        line-height: 18px;
      }
      .currency-select {
        width: 42%;
        margin: 23px 0 15px;
        label {
          &:first-child {
            &:hover {
              background-color: #0e6a4a;
              color: $white !important;
              span {
                div {
                  h6 {
                    color: $white !important;
                  }
                  p {
                    color: $white !important;
                  }
                }
              }
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }
          &:nth-child(2) {
            &:hover {
              background-color: #1d519f;
              color: $white !important;
              span {
                div {
                  h6 {
                    color: $white !important;
                  }
                  p {
                    color: $white !important;
                  }
                }
              }
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }
          &:nth-child(3) {
            &:hover {
              background-color: #7121ff;
              color: $white !important;
              span {
                div {
                  h6 {
                    color: $white !important;
                  }
                  p {
                    color: $white !important;
                  }
                }
              }
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }
          &:nth-child(4) {
            &:hover {
              background-color: #f4ab00;
              color: $white !important;
              span {
                div {
                  h6 {
                    color: $white !important;
                  }
                  p {
                    color: $white !important;
                  }
                }
              }
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }
        }
        .usd {
          background: #0e6a4a;
          color: $white !important;
          span {
            div {
              h6 {
                color: $white !important;
              }
              p {
                color: $white !important;
              }
            }
          }
          svg {
            path {
              fill: $white;
            }
          }
        }
        .eur {
          background: #1d519f;
          color: $white !important;
          span {
            div {
              h6 {
                color: $white !important;
              }
              p {
                color: $white !important;
              }
            }
          }
          svg {
            path {
              fill: $white;
            }
          }
        }
        .usdc {
          background: #7121ff;
          color: $white !important;
          span {
            div {
              h6 {
                color: $white !important;
              }
              p {
                color: $white !important;
              }
            }
          }
          svg {
            path {
              fill: $white;
            }
          }
        }
        .busd {
          background: #f4ab00;
          color: $white !important;
          span {
            div {
              h6 {
                color: $white !important;
              }
              p {
                color: $white !important;
              }
            }
          }
          svg {
            path {
              fill: $white;
            }
          }
        }

        .ant-radio-button-wrapper {
          height: 56px;
          margin-bottom: 9px;
          width: 175px;
          position: relative;
          padding: 8px 15px;
          border-radius: initial;
          span {
            svg {
              position: absolute;
              top: 15px;
              left: 16px;
            }
            div {
              padding-left: 35px !important;
              h6 {
                margin-bottom: 0;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                color: #707070;
              }
            }
          }
          &:nth-child(odd) {
            margin-right: 16px;
          }
        }
      }

      .custom-input {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        width: 90%;
        .coin-input {
          display: flex;
          align-items: center;
          width: 100%;
          position: relative;
          p {
            position: absolute;
            right: 0;
            border-left: 1px solid #d9d9d9;
            padding-left: 10px;
          }
        }
        input {
          background: $white;
          border: 1px solid #dddddd;
          height: 42px;
          border-radius: 0;
        }
        p {
          margin: 0 14px 0 21px;
        }
        h5 {
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 18px;
          background: #4a57e4;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          span {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            color: $darkGrey !important;
            display: block;
            text-transform: uppercase;
            text-align: center;
          }
        }
      }
      .inst {
        display: flex;
        align-items: center;
        img {
          margin-right: 12px;
        }
        p {
          margin-bottom: 0 !important;
        }
      }
    }
    .token {
      background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      padding: 15px 15px;
      margin-bottom: 100px;
      h4 {
        color: $darkGrey;
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 35px;
        text-align: center;
        small {
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 22px;
        }
      }
      .cotribution {
        color: $darkGrey;
        margin-top: 40px;
        > p {
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 22px;
        }
        .contribution-cont {
          display: flex;
          align-items: center;
          justify-content: space-between;
          div {
            h6 {
              font-style: normal;
              font-weight: 500;
              font-size: 26px;
              line-height: 35px;
              margin-bottom: 0;
            }
            p {
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
  .token-price {
    &:after {
      margin: 3rem;
      content: "CRYPTO";
      position: absolute;
      font-style: normal;
      font-weight: 700;
      font-size: 340px;
      line-height: 300px;
      color: rgba(0, 0, 0, 0.04);
      top: 130px;
      left: 0;
      right: 0;
      text-align: center;
      z-index: -1;
      font-family: "Exo", sans-serif;
    }
  }
  .make-payment {
    background: #f5f5f5;
    padding: 30px 0;
    .make-payment-cont {
      .calculate {
        padding: 17px 17px;
        background: #ffffff;
        border: 1px solid #dddddd;
        display: flex;
        div {
          width: 50%;
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 13px;
            color: $darkGrey;
            margin-bottom: 11px;
          }
          h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 22px;
            background: #4a57e4;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
      .err {
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 13px;
        color: #dd0236;
        margin-top: 9px;
      }
      .payment-btn {
        background: $green;
        border-radius: 1px;
        height: 40px;
        color: $white;
        position: relative;
        margin: 23px 0 26px;
        img {
          border-right: 1px solid rgba(255, 255, 255, 0.29);
          margin-right: 20px;
          padding-right: 18px;
        }
        &:after {
          content: url("../../assets/images/triangle.png");
          position: absolute;
          top: -1px;
          right: -1px;
          animation: initial;
        }
      }
      #payment {
        .modal-body {
          border-bottom: 4px solid $green;
        }
        .payment-modal-cont {
          .payment-btns {
            display: flex;
            align-items: center;
            justify-content: center;
            button {
              height: 44px;
              width: 112px;
              background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
              border: 1px solid #f5f5f5;
              border-radius: 5px;
              &:not(:last-child) {
                margin-right: 12px;
              }
            }
          }
          h4 {
            font-style: normal;
            font-weight: 600;
            font-size: 26px;
            line-height: 31px;
            text-transform: capitalize;
            color: $purple;
          }
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-transform: capitalize;
            color: #393939;
            margin-bottom: 25px;
          }
          .submit-btn {
            background: $green;
            border-radius: 1px;
            height: 40px;
            color: $white;
            position: relative;
            margin: 22px auto 0;
            display: table;
            width: 150px;
            img {
              border-right: 1px solid rgba(255, 255, 255, 0.29);
              margin-right: 20px;
              padding-right: 18px;
            }
            &:after {
              content: url("../../assets/images/triangle.png");
              position: absolute;
              top: -1px;
              right: -1px;
              animation: initial;
            }
          }
        }
        .close {
          width: initial;
          background-color: #ffffff;
          color: rgba(112, 112, 112, 0.46);
          border-radius: 100%;
          width: 27px;
          height: 27px;
          opacity: 1;
          line-height: 20px;
          position: absolute;
          right: -15px;
          font-size: 26px;
          font-weight: 400;
          top: -50px;
          z-index: 1;
          &:after {
            content: "";
          }
          &:hover {
            color: rgba(112, 112, 112, 0.46) !important;
          }
        }
      }
      .inst {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        /* or 125% */

        color: #707070;
        img {
          margin-right: 10px;
          height: 26px;
          width: 26px;
        }
      }
      .api-response-message {
        font-size: 12px;
        margin-bottom: 0;
      }
    }
    .ether {
      text-align: center;
      padding: 32px 26px;
      background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      margin-bottom: 20px;
      h5 {
        margin: 15px 0;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 22px;
        background: #4a57e4;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #707070;
      }
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 22px;
        color: #707070;
        span {
          background: #4a57e4;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
@media (max-width: 1280px) {
  .currency-select {
    width: 60% !important;
  }
  .buy-token .token-price:after {
    font-size: 270px;
    top: 12%;
  }
}
@media (max-width: 992px) {
  .currency-select {
    width: 60% !important;
  }
  .buy-token .token-price:after {
    font-size: 210px;
    top: 12%;
  }
}
@media (max-width: 767px) {
  .currency-select {
    width: 90% !important;
  }
  .buy-token .token-price:after {
    font-size: 170px;
    top: 12%;
  }
  .buy-token .token-price .token {
    margin-bottom: 0;
  }
}

@media (max-width: 576px) {
  .currency-select {
    width: 90% !important;
  }
  .buy-token .token-price:after {
    font-size: 110px;
    top: 12%;
  }
  .buy-token .token-price .token {
    margin-bottom: 0;
  }
  .make-payment-cont {
    #payment {
      .modal-dialog {
        margin: 10.5rem 1rem;
      }
    }
  }
}
@media (max-width: 475px) {
  .buy-token .token-price:after {
    font-size: 76px;
  }
}
