@import "./variables";
.buy-token-btn {
  background: #f5f5f5;
  padding: 27px 0;
  margin-bottom: 40px;
  .wallet-btn {
    @include greenButton;
    margin: 0 auto;
    &:after {
      content: url("../../assets/images/triangle.png");
      position: absolute;
      top: -1px;
      right: -1px;
      animation: initial;
    }
    &:hover {
      color: $white;
    }
    img {
      margin-left: 8px;
    }
  }
}
