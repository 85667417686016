@import "./variables";

$greenText: #37B34A;
$redText: #DD2E44;

.metamask-modal {
  text-align: center;
  // border-bottom: 4px solid $green;
  margin-top: 30px;

  .ant-modal-content {
    border-bottom: 4px solid $lightGreenAlt;
  }

  .modal-image {
    margin-top: -120px !important;
  }

  h4 {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: $purple;
    margin-top: 24px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    margin: 30px auto 20px;

    button,
    a {
      background: $green;
      border-radius: 1px;
      padding: 10px 35px;
      height: auto;
      color: $white;
      position: relative;

      &.install-now {
        background: #F7FEFE;
        color: $lightGreenAlt;
      }

      &:after {
        content: url("../../assets/images/triangle.png");
        position: absolute;
        top: -1px;
        right: -1px;
        animation: initial;
      }

      &:hover {
        border-color: initial;
      }

      &.install-later {
        &:after {
          content: url("../../assets/images/triangle-light.png");
          position: absolute;
          top: -6px;
          right: -1px;
        }

        &:hover {
          color: white !important;
        }
      }
    }

    @media screen and (max-width: 380px) {
      flex-direction: column;
      row-gap: 20px;
    }
  }

  .close-btn {
    position: absolute;
    right: -17px;
    top: -60px;
    width: 34px;
    height: 34px;
    border-radius: 99px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
      filter: brightness(1.2);
    }
  }

  &.transaction-success,
  &.transaction-failed {
    h4 {
      margin-bottom: 20px;
    }
  }

  &.transaction-success {
    h4 {
      color: $greenText;
    }
    .ant-modal-content {
      border-bottom: 4px solid $greenText;
    }
  }

  &.transaction-failed {
    h4 {
      color: $redText;
    }
    .ant-modal-content {
      border-bottom: 4px solid $redText;
    }
  }

}