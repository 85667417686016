@import "./_variables";
:where(
    .css-dev-only-do-not-override-acm2ia
  )[ant-click-animating-without-extra-node="true"]::after,
:where(.css-dev-only-do-not-override-acm2ia) .ant-click-animating-node {
  opacity: initial;
  inset-inline-start: initial;
  inset-inline-end: initial;
  animation: initial;
}
.home {
  margin-top: 45px;
  .home-banner {
    margin-top: 45px;
  }
  .home-banner:after {
    margin: 3rem;
    content: "CRYPTO";
    position: absolute;
    font-style: normal;
    font-weight: 700;
    font-size: 340px;
    line-height: 300px;
    color: rgba(0, 0, 0, 0.04);
    top: 20%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: -1;
    font-family: "Exo", sans-serif;
  }
  h1 {
    position: absolute;
    margin: 0px auto;
    right: 0;
    left: 0;
    top: 31%;
    display: inline-table;
    font-style: normal;
    font-weight: 700;
    font-size: 368px;
    line-height: 300px;
    color: rgba(0, 0, 0, 0.04);
    text-transform: uppercase;
    font-family: "Exo", sans-serif;
  }
  .grey-bg {
    background: #f5f5f5;
    padding: 54px 0 80px 0;
  }
  .account-sec {
    margin-top: -80px;
  }
  button {
    &:hover {
      color: $white !important;
    }
  }
  .initial-coin {
    margin: 20px 0 30px;
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 66px;
      line-height: 66px;
      color: $black;
    }
    h4 {
      font-style: normal;
      font-weight: 300;
      font-size: 44px;
      line-height: 66px;
      color: $black;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 29px;
      color: #9d9d9d;
    }
  }
  .token {
    background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 15px 15px;
    margin-bottom: 100px;
    h4 {
      color: $darkGrey;
      font-style: normal;
      font-weight: 500;
      font-size: 35px;
      line-height: 35px;
      text-align: center;
      small {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 22px;
      }
    }
    .cotribution {
      color: $darkGrey;
      margin-top: 40px;
      > p {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 22px;
      }
      .contribution-cont {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 35px;
            margin-bottom: 0;
          }
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }
  }
  .ether {
    text-align: center;
    padding: 26px 26px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    margin-bottom: 20px;
    img {
      margin-top: -50px;
    }
    h5 {
      margin: 15px 0;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 35px;
      background: -webkit-linear-gradient(180deg, #4a57e4 0%, #59aec2 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 22px;
      color: #707070;
    }
  }
  .whitepaper {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 30px 20px;
    text-align: center;
    margin: 200px 0 20px;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #707070;
      display: flex;
      text-align: left;
      span {
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 22px;
        background: -webkit-linear-gradient(180deg, #4a57e4 0%, #59aec2 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        width: 100px;
        display: block;
        margin-left: 20px;
      }
    }
    button {
      background: linear-gradient(180deg, #4a57e4 0%, #59aec2 100%);
      border-radius: 1px;
      height: 40px;
      color: #ffffff;
      position: relative;
      &:after {
        content: url("../../assets/images/triangle.png");
        position: absolute;
        top: -1px;
        right: -1px;
        animation: initial;
      }
      img {
        margin-right: 10px;
      }
      &:hover {
        color: $white;
      }
    }
  }
  .Account {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 30px 15px;
    margin-bottom: 20px;
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 35px;
      color: #575757;
    }
    .wallet-head {
      margin-top: 25px;
    }
    .wallet {
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: $green;
      }
      button {
        margin-left: 25px;
      }
    }
    .kyc-button {
      background: #ffffff;
      border: 1px solid #4a57e4;
      border-radius: 1px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: #4a57e4;
      margin-left: 10px;
      position: relative;
      &:after {
        content: url("../../assets/images/g-tri.png");
        position: absolute;
        top: -1px;
        right: 0;
        animation: initial;
      }
      &:hover {
        color: #4a57e4 !important;
      }
    }
    button {
      background: $green;
      border-radius: 1px;
      height: 40px;
      color: $white;
      position: relative;
      &:after {
        content: url("../../assets/images/triangle.png");
        position: absolute;
        top: -2px;
        right: -1px;
        animation: initial;
      }
    }
  }
  .Progress {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 20px 15px;
    margin: 30px 0 20px;
    .progress-slider {
      position: relative;
      margin-bottom: 30px;
      .soft-cap {
        position: absolute;
        left: 40%;
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 8px;
          line-height: 9px;
          text-align: center;
          color: #aaaaaa;
          margin-bottom: 0;
        }
      }
      .hard-cap {
        position: absolute;
        left: 80%;
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 8px;
          line-height: 9px;
          text-align: center;
          color: #aaaaaa;
          margin-bottom: 0;
        }
      }
      .slider-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h6 {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          text-transform: uppercase;
          color: #4a57e4;
          margin-bottom: 0;
        }
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #aaaaaa;
          margin-bottom: 0;
        }
      }
      .ant-slider {
        margin-top: 0;
        .ant-slider-track {
          background-color: $green;
        }
        .ant-slider-rail {
          background-color: #d5fbee !important;
        }
        .ant-slider-handle {
          &:after {
            box-shadow: 0 0 0 2px $green;
          }
        }
      }
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 35px;
      color: #575757;
    }
    h6 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #aaaaaa;
    }
    .timer {
      display: flex;
      align-items: center;
      justify-content: space-around;
      div {
        background: #ffffff;
        border: 1px solid rgba(14, 106, 74, 0.33);
        border-radius: 2px;
        padding: 7px 15px;
        width: 80px;
        h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 29px;
          line-height: 22px;
          background: -webkit-linear-gradient(180deg, #4a57e4 0%, #59aec2 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 5px;
          text-align: center;
        }
        p {
          margin-bottom: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
          text-transform: uppercase;
          background: -webkit-linear-gradient(180deg, #4a57e4 0%, #59aec2 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
        }
      }
    }
  }
  .calculation {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 20px 15px;
    margin: 60px 0 20px;
    h6 {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      color: #707070;
      margin: 15px 0;
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 35px;
      color: #575757;
    }
    p {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 14px;
      color: #707070;
      margin: 15px 0;
      img {
        margin-right: 5px;
      }
    }
    button {
      width: 257px;
      height: 40px;
      background: $green;
      border: 1px solid #cfffee;
      border-radius: 1px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: $white;
      margin: 0px auto;
      display: table;
      position: relative;
      &:after {
        content: url("../../assets/images/triangle.png");
        position: absolute;
        top: -2px;
        right: -1px;
        animation: initial;
      }
    }
    .input-select {
      display: flex;
      align-items: center;
      .input-group {
        width: 192px;
        background: #ffffff;
        border: 1px solid #c7c7c7;
        border-radius: 2px;
        padding: 7px 0;
      }
      .form-control {
        border: initial;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 22px;
        color: $black;
        padding: 0 12px;
        height: 40px;
        &:hover,
        &:focus {
          box-shadow: initial;
        }
        &::placeholder {
          font-size: 12px;
        }
      }
      .ant-select {
        .ant-select-selector {
          height: 40px;
          border: initial;
          border-left: 1px solid #aaaaaa;
          border-radius: initial;
          &:focus {
            box-shadow: initial;
            outline: none;
          }
          .ant-select-selection-item {
            text-align: end;
            position: relative;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 40px;
            color: $black;
            // &:before {
            //   content: "|";
            //   position: absolute;
            //   margin-left: -1px0px;
            //   color: #aaaaaa;
            // }
          }
        }
        .ant-select-arrow {
          svg {
            path {
              fill: $black;
            }
          }
        }
      }
      .equals {
        margin: 0 14px 0 8px;
      }
      .price {
        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: $black;
          margin-bottom: 0;
        }
        p {
          font-style: normal;
          font-weight: 300;
          font-size: 19px;
          line-height: 20px;
          text-transform: uppercase;
          color: #4a57e4;
          margin: 0 0;
        }
      }
    }
  }
  .Charts {
    background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
    border: 1px solid #d1d1d1;
    border-radius: 5px;
    padding: 20px 15px;
    margin: 50px 0 20px;
    .charts-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 35px;
      a {
        background: $green;
        border: 1px solid #cfffee;
        border-radius: 1px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        color: $white;
        height: 32px;
        width: 100px;
        text-align: center;
        position: relative;
        &:after {
          content: url("../../assets/images/triangle.png");
          position: absolute;
          top: -5px;
          right: -1px;
          animation: initial;
        }
      }
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 35px;
      color: #575757;
      display: flex;
      align-items: center;
      img {
        margin-right: 20px;
      }
    }
    .purchase {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 14px;
      text-transform: capitalize;
      color: #4a57e4;
    }
    .Bonus {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 14px;
      text-transform: capitalize;
      color: #f4ab00;
    }
    .table {
      thead {
        tr {
          th {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            text-transform: uppercase;
            color: $black;
            text-align: center;
            &:first-child,
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              min-width: 150px;
            }
            &:nth-child(2) {
              border-left: 1px solid #c2dcd3;
            }
            &:nth-child(3) {
              border-left: 1px solid #c2dcd3;
              text-align: left;
              padding-left: 30px;
            }
            &:nth-child(4) {
              border-left: 1px solid #c2dcd3;
            }
          }
        }
      }
      tr {
        td {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: $black;
          text-align: center;
          padding: 5px 12px;
          vertical-align: middle;
          &:first-child {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              margin-right: 7px;
            }
          }
          &:nth-child(2) {
            border-left: 1px solid #c2dcd3;
            span {
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 9px;
              color: $green;
              margin-right: 7px;
              display: block;
              img {
                width: 7px;
              }
            }
          }
          &:nth-child(3) {
            border-left: 1px solid #c2dcd3;
          }
          &:nth-child(4) {
            border-left: 1px solid #c2dcd3;
            text-align: left;
            padding-left: 18px;
            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .coins-img {
    position: relative;
  }
  .symbol-img {
    position: absolute;
    right: 0;
    left: -5%;
    top: 28%;
    width: 30% !important;
    margin: 0px auto;
  }
  .modal-btn {
    text-align: left;
    display: flex;
    align-items: center;
    img {
      border-right: 1px solid rgba(255, 255, 255, 0.29);
      margin-right: 27px;
      padding-right: 18px;
    }
  }
  .thanks-sec {
    background: #f5f5f5;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    background-image: url("../../assets/images/blur.png");
    background-repeat: no-repeat;
    background-position: right bottom 25px;
    padding: 25px 0;
    .coins-img {
      animation: rotation 15s linear infinite;
      width: 100%;
      border-radius: 100px;
    }
    .thanks-cont {
      padding-left: 30px;
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 35px;
        color: #575757;
      }
      p {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
        color: #707070;
        margin-bottom: 20px;
        padding-left: 20px;
      }
      ol {
        padding-left: 35px;
        li {
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 26px;
          color: #707070;
          margin-bottom: 20px;
        }
      }
    }
  }
  @keyframes rotation {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  .wing-img {
    animation: eye-bounce 2s infinite alternate ease-in-out;
    -webkit-animation: eye-bounce 2s infinite alternate ease-in-out;
    position: absolute;
  }
  @keyframes eye-bounce {
    0% {
      transform: translateY(-25px) scale(0.9);
    }
    100% {
      transform: translateY(5px) scale(0.9);
    }
  }
  @-o-keyframes eye-bounce {
    0% {
      transform: translateY(-25px) scale(0.9);
    }
    100% {
      transform: translateY(5px) scale(0.9);
    }
  }
  @-moz-keyframes eye-bounce {
    0% {
      transform: translateY(-25px) scale(0.9);
    }
    100% {
      transform: translateY(5px) scale(0.9);
    }
  }
  @-webkit-keyframes eye-bounce {
    0% {
      transform: translateY(-25px) scale(0.9);
    }
    100% {
      transform: translateY(5px) scale(0.9);
    }
  }
}
#exampleModal {
  .modal-body {
    border-bottom: 4px solid $green;
  }
  .modal-cont {
    text-align: center;
    img {
      margin-top: -120px;
    }
    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 31px;
      text-transform: uppercase;
      color: #4a57e4;
      margin-top: 22px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #707070;
      display: initial;
    }
  }
  .close {
    width: initial;
    background-color: #ffffff;
    color: rgba(112, 112, 112, 0.46);
    border-radius: 100%;
    width: 27px;
    height: 27px;
    opacity: 1;
    line-height: 20px;
    position: absolute;
    right: -15px;
    font-size: 26px;
    top: -50px;
    z-index: 1;
    &:after {
      content: "";
    }
    &:hover {
      color: rgba(112, 112, 112, 0.46) !important;
    }
  }
}
#exampleModal1 {
  .modal-body {
    padding: 30px 30px;
    text-align: center;
    border-bottom: 4px solid $green;
    .kyc-img {
      margin-top: -120px !important;
    }
  }
  .kyc-modal-cont {
    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 31px;
      text-transform: capitalize;
      color: $purple;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #707070;
    }
    img {
      margin: 0px 0 20px;
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 21px;
      text-align: center;
      text-transform: capitalize;
      color: $purple;
    }
    button {
      margin: 0px auto;
      display: table;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }
    .file-inner {
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      max-width: 100%;
      margin: 0px auto 30px;
      padding: 25px 0;
      p {
        margin: 11px 0 25px;
      }
    }
    p {
      a {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $green;
      }
    }
  }
  .close {
    width: initial;
    background-color: #ffffff;
    color: rgba(112, 112, 112, 0.46);
    border-radius: 100%;
    width: 27px;
    height: 27px;
    opacity: 1;
    line-height: 20px;
    position: absolute;
    right: -15px;
    font-size: 26px;
    font-weight: 400;
    top: -50px;
    z-index: 1;
    &:after {
      content: "";
    }
    &:hover {
      color: rgba(112, 112, 112, 0.46) !important;
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 900px;
    }
  }
}
@media (max-width: 1280px) {
  .home .home-banner:after {
    margin: 3rem;
    font-size: 270px;
    top: 20%;
  }
}
@media (max-width: 1140px) {
  .home {
    .initial-coin {
      h3 {
        font-size: 45px;
        line-height: 45px;
      }
      h4 {
        font-size: 32px;
        line-height: 45px;
      }
      p {
        font-size: 18px;
      }
    }
  }
  .home .home-banner:after {
    font-size: 240px;
  }
}
@media (max-width: 767px) {
  .home {
    .home-banner {
      &:after {
        font-size: 150px;
      }
    }
    .token {
      margin-bottom: 50px;
    }
    .whitepaper {
      margin: 30px 0 50px;
    }
    .wing-img {
      position: initial;
    }
    .calculation {
      margin: 35px 0 20px;
    }
    .Charts {
      margin: 30px 0 20px;
    }
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    margin: 12.75rem auto;
  }
}
@media (max-width: 576px) {
  .home .home-banner:after {
    font-size: 110px;
    top: 12%;
    margin: 0;
  }
  .home .initial-coin h3 {
    font-size: 30px;
  }
  .home .initial-coin h4 {
    font-size: 22px;
  }
  .home .thanks-sec .thanks-cont p {
    word-wrap: break-word;
  }
}
@media (max-width: 475px) {
  .home .home-banner:after {
    font-size: 76px;
  }
}
