@import "./variables";
.warning-box {
  margin: 210px auto;
  width: 100%;
  display: block;
  text-align: center;
  h4 {
    font-style: normal;
    font-weight: 300;
    font-size: 58px;
    line-height: 58px;
    color: #aaaaaa;
    margin-top: 19px;
  }
}
