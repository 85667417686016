@import "./variables";
.transactions {
  .transactions-header {
    background: #f5f5f5;
    padding: 10px 10px;
    .search-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > input {
        background: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 2px;
        height: 42px;
        max-width: 604px;
        position: relative;
        padding: 6px 12px 6px 50px;
        &::placeholder {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          color: #aaaaaa;
        }
      }
      .search-icon {
        position: absolute;
        left: 28px;
      }
      button {
        background: $white;
        border: 1px solid $white;
        border-radius: 1px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        height: 42px;
        width: 164px;
        line-height: 16px;
        text-align: center;
        color: $purple;
        margin-left: 10px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &:focus {
          box-shadow: initial;
        }
        .dropdown-toggle::after {
          content: "";
        }
        img {
          margin-right: 10px;
        }
        &:after {
          content: url("../../assets/images/g-tri.png");
          position: absolute;
          top: -1px;
          right: 0;
          animation: initial;
          border: initial;
        }
        &:hover {
          color: $green !important;
        }
      }
      .dropdown-menu {
        padding: 7px 14px;
        margin-top: 10px;
        background: #ffffff;
        border: 1px solid $green;
        border-radius: 0;
        h6 {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 16px;
          color: $darkGrey;
          margin: 15px 0;
        }
        .ant-checkbox-wrapper {
          margin-inline-start: 0;
          margin-left: 10px;
          .ant-checkbox-inner {
            background: #d9d9d9;
          }
        }
      }
    }
  }
  .transactions-data {
    min-height: 55vh;
    table {
      margin-top: 55px;
      thead {
        tr {
          th {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 16px;
            color: $purple;
            border: initial;
            min-width: 220px;
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              min-width: 150px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 16px;
            color: #393939;
            border: initial;
            span {
              font-style: normal;
              font-weight: 300;
              font-size: 14px;
              line-height: 16px;
              display: block;
              margin-top: 9px;
            }
            &:first-child {
              position: relative;
              padding-left: 46px;

              img {
                position: absolute;
                left: 11px;
                top: 20px;
              }
            }
            .wallet-btn {
              &:hover {
                svg {
                  path {
                    fill: #00ab1b;
                  }
                }
              }
            }
            .eye-btn {
              &:hover {
                svg {
                  path {
                    fill: $purple;
                  }
                }
              }
            }
            .delete-btn {
              &:hover {
                svg {
                  path {
                    fill: #e26d00;
                  }
                }
              }
            }
            button {
              background-color: transparent;
              border: initial;
              padding: 0;

              &:not(:last-child) {
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
  }
  #transaction {
    .modal-body {
      border-bottom: 4px solid $green;
      .transaction-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0;
        div {
          h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            text-transform: capitalize;
            color: #393939;
          }
          p {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 21px;
            text-transform: capitalize;
            color: #393939;
          }
        }
      }
      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 31px;
        text-transform: capitalize;
        color: $green;
      }
      .transaction-info {
        margin-bottom: 50px;
        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 21px;
          text-transform: capitalize;
          color: #393939;
          margin-bottom: 20px;
        }
        div {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            text-transform: capitalize;
            color: #393939;
            width: 190px;
          }
          p {
            margin-bottom: 0;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 21px;
            text-transform: capitalize;
            color: $darkGrey;
          }
        }
      }
      .token-details {
        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 21px;
          text-transform: capitalize;
          color: #393939;
          margin-bottom: 20px;
        }
        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            text-transform: capitalize;
            color: #393939;
            width: 190px;
          }
          p {
            margin-bottom: 0;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 21px;
            text-transform: capitalize;
            color: $darkGrey;
            display: flex;
            align-items: center;
            img {
              margin: 0 7px;
            }
          }
        }
      }
    }

    .close {
      width: initial;
      background-color: #ffffff;
      color: rgba(112, 112, 112, 0.46);
      border-radius: 100%;
      width: 27px;
      height: 27px;
      opacity: 1;
      line-height: 20px;
      position: absolute;
      right: -15px;
      font-size: 26px;
      font-weight: 400;
      top: -50px;
      z-index: 1;
      &:after {
        content: "";
      }
      &:hover {
        color: rgba(112, 112, 112, 0.46) !important;
      }
    }
  }
}
@media (min-width: 576px) {
  #transaction .modal-dialog {
    max-width: 881px;
    margin: 6.75rem auto !important;
  }
}
