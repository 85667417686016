@import "./variables";
:where(
    .css-dev-only-do-not-override-acm2ia
  )[ant-click-animating-without-extra-node="true"]::after,
:where(.css-dev-only-do-not-override-acm2ia) .ant-click-animating-node {
  opacity: initial;
  inset-inline-start: initial;
  inset-inline-end: initial;
  animation: initial;
}
.faqs {
  .buy-token-btn {
    background: #f5f5f5;
    padding: 27px 0;
    margin-bottom: 40px;
    .wallet-btn {
      @include greenButton;
      margin: 0 auto;
      &:after {
        content: url("../../assets/images/triangle.png");
        position: absolute;
        top: -1px;
        right: -1px;
        animation: initial;
      }
      img {
        margin-left: 8px;
      }
    }
  }
  .profile-cont {
    background: $white;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 15px 15px;

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 35px;
      color: $purple;
      margin-bottom: 15px;
    }
    .ant-collapse {
      background-color: transparent;
      border: initial;
      .ant-collapse-item {
        border: 1px solid $green;
        border-radius: 1px;
        margin-bottom: 22px;
        .ant-collapse-header {
          background: $white;
          border: none;
          .ant-collapse-expand-icon {
            position: absolute;
            right: 10px;
            span {
              svg {
                width: 11px;
                path {
                  fill: $green;
                }
              }
            }
          }
          .ant-collapse-header-text {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: $purple;
          }
        }
        .ant-collapse-content {
          border-top: initial;

          p {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 22px;
            color: $darkGrey;
          }
        }
      }
    }
  }
}
