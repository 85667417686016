@import "./variables";
:where(
    .css-dev-only-do-not-override-acm2ia
  )[ant-click-animating-without-extra-node="true"]::after,
:where(.css-dev-only-do-not-override-acm2ia) .ant-click-animating-node {
  opacity: initial;
  inset-inline-start: initial;
  inset-inline-end: initial;
  animation: initial;
}
.signup-form {
  background: #f5f5f5;
  padding: 100px 0;

  .form-cont {
    padding: 20px 25px;
    background: #fefefe;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    margin: 0 auto;

    .login-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        a {
          color: $green;
        }
      }
    }
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 22px;
      color: $black;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      color: $darkGrey;
    }
    label {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 35px;
      color: #575757;
    }

    .form-group {
      margin-top: 30px;
      .password-input {
        position: relative;
      }
      button {
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 1;
        background-color: transparent;
        border: initial;
      }
    }
    .form-control {
      border: initial;
      border-bottom: 1px solid #094d07;
      border-radius: 0px;
      padding-left: 0;
      &:focus {
        box-shadow: initial;
        outline: none;
      }
    }
    .forgot {
      display: inline-block;
      margin-left: auto;
      display: table;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #3580f1;
    }
    .custom-check {
      margin: 20px 0 20px;
      label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        color: #3f0630;
      }
      .ant-checkbox-inner {
        background: #d9d9d9;
      }
      a {
        color: #00a3ff;
      }
    }
    .login-btn {
      background: $green;
      border: 1px solid $green;
      border-radius: 5px;
      color: $white;
      width: 200px;
      height: 50px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $white;
      img {
        margin-left: 20px;
      }

      &:hover {
        color: $white;
      }
    }
  }
  .pass-list {
    margin: 20px 0 60px;
    li {
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 20px;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 12px;
      color: #575757;
      margin-bottom: 10px;
      img {
        position: absolute;
        left: 0;
        max-width: 10px;
      }
    }
  }
  .login-btns {
    width: 30%;
    display: flex;
    align-items: center;
    h4 {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 35px;
      color: $green;
      margin: 0 24px 0 31px;
    }
    a, .social-login {
      background: #f9ffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
      height: 52px;
      width: 52px !important;
      display: inline-block;
      margin-right: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $black;
      padding: 10px 10px;
      cursor: pointer;

      &:first-child {
        margin-right: 20px;
      }
      &:first-child,
      &:nth-child(2) {
        width: 200px;
      }
      &:last-child {
        width: 300px;
      }
    }
  }
}
