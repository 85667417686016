$grey: #eeeeee;
$green: linear-gradient(180deg, #4a57e4 0%, #59aec2 100%);
$lightGreen: #f3fff2;
$lightGreenAlt: #58A6C6;
$darkGrey: #777777;
$lightGrey: #f3f3f3;
$whiteGrey: #f6f6f6;
$black: #000000;
$white: #ffffff;
$lightBlue: #0681a8;
$purple: #4d67de;

@mixin greenButton {
  background: $green;
  border: 1px solid #cfffee;
  border-radius: 1px;
  color: $white;
  display: flex;
  align-items: center;
  height: 40px;
  margin: 30px auto 0;
  position: relative;
}
