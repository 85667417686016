@import "./variables";
:where(
    .css-dev-only-do-not-override-acm2ia
  )[ant-click-animating-without-extra-node="true"]::after,
:where(.css-dev-only-do-not-override-acm2ia) .ant-click-animating-node {
  opacity: initial;
  inset-inline-start: initial;
  inset-inline-end: initial;
  animation: initial;
}
.profile {
  .token-head {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 35px;
    color: $green;
    margin-bottom: 9px;
  }

  .profile-cont {
    background: $white;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 15px 15px;

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 35px;
      color: $purple;
      margin-bottom: 15px;
    }
    .ant-collapse {
      background-color: transparent;
      border: initial;
      .ant-collapse-item {
        border-width: 1px;
        border-image: linear-gradient(to left, #4a57e4, #59aec2) 1;
        border-style: solid;
        border-radius: 1px;
        margin-bottom: 22px;
        .ant-collapse-header {
          background: $white;
          border: none;
          .ant-collapse-expand-icon {
            position: absolute;
            right: 10px;
            span {
              svg {
                width: 11px;
                path {
                  fill: $green;
                }
              }
            }
          }
          .ant-collapse-header-text {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 16px;
            color: $purple;
          }
        }
        .ant-collapse-content {
          border-top: initial;
          .form-group {
            margin-bottom: 27px;
            label {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 16px;
              color: #393939;
            }
            input {
              background: $white;
              border: 1px solid #dddddd;
              border-radius: 0;
              &::placeholder {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                color: $darkGrey;
              }
            }
            .ant-select-selector {
              background: $white;
              border: 1px solid #dddddd;
              border-radius: 0;
              height: 38px;
              display: flex;
              align-items: center;
              &::placeholder {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                color: $darkGrey;
              }
            }
          }
          button {
            display: inline-block;
          }
        }
      }
    }
    .security {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .switch {
        position: relative;
        padding-left: 60px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $darkGrey;
        .ant-switch {
          position: absolute;
          left: 0;
        }
        .ant-switch-checked {
          .ant-switch-inner {
            background: $green;
          }
        }
      }
    }
  }
  .update-btn {
    background: $green;
    border: 1px solid #21847f;
    width: 182px;
    height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: $white;
    border-radius: 0;
    margin: 20px 15px 0;
    display: block;
    &:hover {
      color: $white;
    }
  }
  .Account {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 30px 15px;
    margin-bottom: 20px;
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 35px;
      color: #575757;
    }
    .wallet-head {
      margin-top: 25px;
    }
    .wallet {
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        color: $green;
      }
      button {
        margin-left: 25px;
      }
    }
    .kyc-button {
      background: #ffffff;
      border: 1px solid $green;
      border-radius: 1px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: $purple;
      margin-left: 10px;
      position: relative;
      &:after {
        content: url("../../assets/images/g-tri.png");
        position: absolute;
        top: -1px;
        right: 0;
        animation: initial;
      }
      &:hover {
        color: $green !important;
      }
    }
    button {
      background: $green;
      border-radius: 1px;
      height: 40px;
      color: $white;
      position: relative;
      &:after {
        content: url("../../assets/images/triangle.png");
        position: absolute;
        top: -1px;
        right: -1px;
        animation: initial;
      }
      &:hover {
        color: $white !important;
      }
    }
    .modal-btn img {
      border-right: 1px solid rgba(255, 255, 255, 0.29);
      margin-right: 27px;
      padding-right: 18px;
    }
  }
  .verification {
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 22px;
      color: $purple;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #575757;
      margin-bottom: 10px;
    }
    h6 {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 27px;
      color: $green;
    }
  }
  .two-factor {
    background: #f5f5f5;
    padding: 50px 0;
    margin-top: 32px;
    position: relative;
    .two-factor-cont {
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 16px;
        color: #393939;
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: $darkGrey;
        margin: 21px 0 50px;
      }
      .two-factor-status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
          background: $green;
          border: 1px solid $green;
          border-radius: 0;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          color: $white;
          width: 147px;
          height: 40px;
          &:hover {
            color: $white;
          }
        }
        div {
          display: flex;
          align-items: center;
          h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: #393939;
            margin-bottom: 0;
          }
          .disable {
            background: #8f8f8f;
            border: 1px solid #808080;
            height: 35px;
            width: 147px;
            text-align: center;
            margin-left: 18px;
            display: initial;
            line-height: 34px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
            color: $white;
          }
        }
      }
    }
    #fa-modal {
      .modal-body {
        padding: 30px 30px;
        border-bottom: 4px solid $green;
        p {
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          text-transform: capitalize;
          color: #393939;
          margin-bottom: 12px;
          span {
            color: $green;
          }
        }
        h5 {
          font-style: normal;
          font-weight: 600;
          font-size: 26px;
          line-height: 31px;
          text-transform: capitalize;
          color: $green;
        }
        .fa-qr {
          display: flex;
          align-items: center;
          margin: 20px 0;
          .fa-qr-cont {
            margin-right: 31px;
            border: 1px solid #d0d0d0;
          }
          .fa-qr-input {
            h6 {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 21px;
              text-transform: capitalize;
              color: #393939;
            }
            input {
              background: #ffffff;
              border: 1px solid #c8c8c8;
              border-radius: 2px;
              height: 40px;
              margin: 20px 0;
              &::placeholder {
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 21px;
                text-transform: capitalize;
                color: #afafaf;
              }
            }
            button {
              background: $green;
              border: 1px solid $green;
              border-radius: 0;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 16px;
              text-align: center;
              color: $white;
              height: 40px;
              width: 170px;
            }
          }
        }
        .orange-text {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          text-transform: capitalize;
          color: #e26d00;
        }
      }
      .close {
        width: initial;
        background-color: #ffffff;
        color: rgba(112, 112, 112, 0.46);
        border-radius: 100%;
        width: 27px;
        height: 27px;
        opacity: 1;
        line-height: 20px;
        position: absolute;
        right: -15px;
        font-size: 26px;
        font-weight: 400;
        top: -50px;
        z-index: 1;
        &:after {
          content: "";
        }
        &:hover {
          color: rgba(112, 112, 112, 0.46) !important;
        }
      }
    }
    .hand-img {
      margin-top: -110px;
      position: absolute;
    }
  }
  .token-cont {
    border: 1px solid $green;
    border-radius: 1px;
    background-color: transparent;
    .withdraw {
      padding: 23px 0;
      border-bottom: 1px solid #d2d2d2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 22px;
        text-transform: capitalize;
        color: #707070;
      }
      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 35px;
        color: #707070;
        display: flex;
        align-items: center;
        small {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: #707070;
          margin-left: 16px;
        }
      }
      h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-transform: capitalize;
        color: #707070;
      }
      button {
        background: $green;
        border: 1px solid #21847f;
        width: 200px;
        height: 50px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 16px;
        text-align: center;
        color: $white;
        border-radius: 0;
        margin: 20px 15px 0;
        display: block;
        &:hover {
          color: $white;
        }
        &:after {
          content: url("../../assets/images/triangle.png");
          position: absolute;
          top: -1px;
          right: -1px;
          animation: initial;
        }
      }
    }
    .withdraw-token {
      border-bottom: initial;
      div {
        h6 {
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 22px;
          text-transform: capitalize;
          color: $purple;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          text-transform: capitalize;
          color: #707070;
          margin: 13px 0 30px;
        }
        a {
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 22px;
          text-transform: capitalize;
          color: $purple;
        }
      }
    }
  }
  .token-cont:after {
    margin: 3rem;
    content: "CRYPTO";
    position: absolute;
    font-style: normal;
    font-weight: 700;
    font-size: 340px;
    line-height: 300px;
    color: rgba(0, 0, 0, 0.04);
    top: 100px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: -1;
    font-family: "Exo", sans-serif;
  }
}
@media (max-width: 1280px) {
  .profile .token-cont:after {
    font-size: 270px;
    top: 12%;
  }
}
@media (max-width: 992px) {
  .currency-select {
    width: 60% !important;
  }
  .profile .token-cont:after {
    font-size: 210px;
    top: 12%;
  }
}
@media (max-width: 767px) {
  .profile .token-cont:after {
    font-size: 170px;
    top: 12%;
  }
  .profile .two-factor .hand-img {
    position: initial;
    margin-top: 0;
    margin: 0px auto;
    display: block;
  }
}
@media (max-width: 576px) {
  .profile .token-cont:after {
    font-size: 110px;
    top: 12%;
  }
  .profile .token-cont .withdraw {
    flex-wrap: wrap;
  }
  .profile .Account .wallet {
    flex-wrap: wrap;
    justify-content: center;
    button {
      margin: 0;
    }
    p {
      margin-bottom: 10px;
    }
  }
}
@media (max-width: 475px) {
  .profile .token-cont:after {
    font-size: 70px;
    top: 12%;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
  }
}
