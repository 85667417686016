* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
a {
  text-decoration: none !important;
}

.error {
  color: red !important;
  font-weight: bold !important;

  &.form-field-error {
    margin-top: 5px;
    font-size: 11px;
  }
}

.success {
  color: green !important;
  font-weight: bold !important;
}

button:disabled {
  opacity: 0.6;
}

input:disabled {
  cursor: not-allowed;
  opacity: 0.6 !important;
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1140px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1360px;
  }
}

@media (min-width: 767px) {
  .br-block {
    display: block;
  }
}
