@import "./variables";
:where(
    .css-dev-only-do-not-override-acm2ia
  )[ant-click-animating-without-extra-node="true"]::after,
:where(.css-dev-only-do-not-override-acm2ia) .ant-click-animating-node {
  opacity: initial;
  inset-inline-start: initial;
  inset-inline-end: initial;
  animation: initial;
}
.footer {
  background: transparent;
  padding: 20px 0 0;
  margin-top: 60px;
  // position: fixed;
  // left: 0;
  // bottom: 0;
  // width: 100%;
  .footer-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul {
      display: flex;
      align-items: center;
      li {
        a {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: $green;
        }
        &:not(:last-child) {
          margin-right: 60px;
        }
      }
    }
    div {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        background: #4a57e4;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-right: 31px;
      }
      .ant-select-selector {
        background: $green;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: $white;
        height: 40px;
        border-radius: initial;
        .ant-select-selection-item {
          text-transform: uppercase;
        }
      }
    }
    .ant-select-arrow {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
  .footer-list {
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #22971e;
    }
    ul {
      li {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        a {
          color: #fff;
          margin-left: 9px;
          font-size: 14px;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    input {
      height: 45px;
      border-radius: 4px;
      margin-bottom: 11px;
      &::placeholder {
        color: $black;
      }
    }
    .subs-btn {
      background: #3a3a3a;
      border: 1px solid #000000;
      border-radius: 0px 3px 3px 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-transform: capitalize;
      color: $white;
      padding: 10px 10px;
      width: 100%;
      height: 45px;
      img {
        margin-right: 10px;
      }
    }
    .social-list-footer {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 44px;
        height: 44px;
        border: 2px solid #22971e;
        border-radius: 50px;
        margin-right: 10px;
      }
    }
  }
}
.copyRight {
  background: $green;
  text-align: center;
  padding: 6px 0;
  margin-top: 20px;
  p {
    color: $white;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
  }
  .social-list {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    li {
      &:not(:last-child) {
        margin-right: 28px;
      }
    }
  }
}
@media (max-width: 767px) {
  .footer {
    .footer-box {
      display: initial;
      .footer-nav-list {
        justify-content: center;
        li {
          &:not(:last-child) {
            margin-right: 30px;
          }
          a {
            font-size: 16px;
          }
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .footer {
    .footer-box {
      display: initial !important;
    }
    .footer-nav-list {
      flex-wrap: wrap !important;
      margin-bottom: 20px;
      li {
        margin-bottom: 15px;
      }
    }
  }
}
